<template>
    <component :is="page"/>
</template>

<script>
    import web from '@/pages/web'
    import mobile from '@/pages/mobile'
    export default {
        name: 'App',
        data() {
          return {
            page: null
          }
        },
        created () {
          this.page = this.isMobile() ? mobile : web
        },
        methods: {
            isMobile() {
              return navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                );
            }
        }
    };
</script>

