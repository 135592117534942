import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import "./utils/flexible.js"
import "./plugin/plugin"
import 'element-ui/lib/theme-chalk/index.css';
import "./styles/reset.css"
Vue.config.productionTip = false
Vue.prototype.$t = i18n.$t
new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
