import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { crc32 } from 'crc'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n);
import zh_CN from './json/zh_CN';
import vi from './json/vi';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import viLocal from 'element-ui/lib/locale/lang/vi'
/*---------使用语言包-----------*/
let defaultLang = location.pathname.replace('/', '')
if (defaultLang === 'zh-CN') defaultLang = 'zh_CN'
let locale = defaultLang || localStorage.getItem('lang') || window.locale || 'vi'
document.body.classList = [locale];

const i18n = new VueI18n({
  locale: locale, // 语言标识 
  silentTranslationWarn: true,
  messages: {
    zh_CN: {
      ...zhLocale,
      ...zh_CN
    },//中文简体
    vi: {
      ...vi,
      ...viLocal
    }
  },
  //   silentTranslationWarn: true,
})
ElementLocale.i18n((key, value) => i18n.t(key, value))

const lang = (key, o) => {
  let hashKey = ''
  if (process.env.NODE_ENV === 'production') {
    hashKey = key;
  } else {
    hashKey = `k${crc32(key).toString(16)}`
  }
  // console.log(hashKey,"hashKey")
  // //已经在外部用 crc.js 计算过了
  let words = i18n.t(hashKey, o)
  // console.log(words,"key, o=====",key, o)
  if (words === hashKey) {
    words = key
    // console.log(key, '-没翻译')
  }
  return words
}

i18n.$t = lang
export default i18n;