<template>
    <div class="mobile">
        <div class="content">
            <div class="header">
                <div class="menu-toolbar flexbox sb">
                    <img src="../assets/images/logo.png" class="logo" />
                    <el-dropdown trigger="click">
                        <div class="menudown">
                            <span class="flexbox">
                                <img src="../assets/images/language.png" class="languageimg">
                            </span>
                        </div>
                        <el-dropdown-menu
                            slot="dropdown"
                            class="menu-dropdown"
                            transfer>
                            <el-dropdown-item
                                class="flexbox fs"
                                v-for="(i, j) in lang"
                                :key="j"
                                @click.native="setLang(i)"
                                ><img :src="i.img" alt="" />{{
                                    i.name
                                }}</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="mainbody flexbox col">
                <div class="swiperbox">
                    <div class="swiper swiper-container1 swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item, index) in banner[$i18n.locale || 'zh_CN']" :key="index">
                                <img :src="item" width="100%"/>
                                <div style="height: 50px;"></div>
                            </div>
                        </div>
                        <div class="swiper-paginationa swiper-pagination"></div>
                    </div>
                </div>
                <div class="serve flexbox col">
                    <div class="title">
                        {{ $t('我们致力为客户提供的服务') }}
                    </div>
                    <div class="desc">
                        {{ $t('专注于以游戏为载体的智慧生态系统创作和运营，并挚邀请您提出宝贵建议') }}
                    </div>
                    <div class="bcontent flexbox">
                        <div class="swiper swiper-container swiper-container4">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide flexbox se" v-for="(i, j) in service" :key="j">
                                    <div class="item flexbox col dotswiperitem"
                                        v-for="(a, b) in i" :key="b">
                                        <img :src="a.img" width="150" />
                                        <p class="name1">{{ a.name }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-paginationa swiper-pagination"></div>
                        </div>
                    </div>
                </div>
                <div class="plan">
                    <div class="inner">
                        <div class="plan-cont">
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div class="cont-left-title">
                                        {{ $t('包网产品') }}
                                    </div>
                                </div>
                                <div class="col-9 cont-right flexbox a1">
                                    <div class="item">
                                        <div
                                            class="text-val-tit flexbox text-bold fs21">
                                            {{ $t('GTG正常包网') }}
                                        </div>
                                        <div
                                            class="text-val-tit flexbox a1"
                                            v-html="
                                                $t('Web、H5、原生APP(IOS、安卓)<br/>管理后台系统<br/>代理后台系统')
                                            "></div>
                                    </div>
                                    <div class="item">
                                        <div
                                            class="text-val-tit flexbox text-bold fs21">
                                            {{ $t('GTG体育包网') }}
                                        </div>
                                        <div
                                            class="text-val-tit flexbox a1"
                                            v-html="
                                                $t('Web、H5、原生APP(IOS、安卓)<br/>管理后台系统<br/>代理后台系统')
                                            "></div>
                                    </div>
                                    <div class="item">
                                        <div
                                            class="text-val-tit flexbox text-bold fs21">
                                            {{ $t('GTG代运营包网') }}
                                        </div>
                                        <div
                                            class="text-val-tit flexbox a1"
                                            v-html="
                                                $t('Web、H5、原生APP(IOS、安卓)<br/>管理后台系统<br/>代理后台系统')
                                            "></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div class="cont-left-title">
                                        {{ $t('开版费') }}
                                    </div>
                                </div>
                                <div class="col-9 cont-right a2">
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600"
                                                >5000U/{{$t('月')}}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600"
                                                >5000U/{{$t('月')}}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600">{{
                                                $t('免')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div class="cont-left-title">
                                        {{ $t('包网服务费') }}
                                    </div>
                                </div>
                                <div class="col-9 cont-right a1">
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600"
                                                >1500U/{{$t('月')}}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600"
                                                >1500U/{{$t('月')}}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600">{{
                                                $t('免')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div class="cont-left-title">{{$t('游戏场馆费')}}</div>
                                </div>
                                <div class="col-9 cont-right a2">
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600"
                                                >10%{{ $t('起') }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600"
                                                >10%{{ $t('起') }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            <span style="color: #ff8600">15%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div class="cont-left-title">
                                        {{ $t('财务服务') }}
                                    </div>
                                </div>
                                <div
                                    class="col-9 cont-right"
                                    style="background-color: rgb(255, 245, 231)">
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('财务自己负责') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('财务自己负责') }}<br />
                                            {{ $t('免费三方通道对接') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{
                                                $t(
                                                    '提供免费出入款服务(不含通道手续费)'
                                                )
                                            }}<br />
                                            {{ $t('免费三方通道对接服务') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div class="cont-left-title">
                                        {{ $t('客服服务') }}
                                    </div>
                                </div>
                                <div
                                    class="col-9 cont-right"
                                    style="background-color: rgb(242, 238, 234)">
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('自运营') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('自运营') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('提供7*24小时在线客服服务') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div class="cont-left-title">
                                        {{ $t('运营服务') }}
                                    </div>
                                </div>
                                <div
                                    class="col-9 cont-right"
                                    style="background-color: rgb(255, 245, 231)">
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('自运营') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('自运营') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div
                                            class="text-val-tit flexbox"
                                            v-html="
                                                $t(
                                                    '提供日常运营活动、优惠、红包雨等<br />多种促销增加会员粘性'
                                                )
                                            "></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="col-3 text-center cont-left">
                                    <div
                                        class="cont-left-title"
                                        v-html="
                                            $t('其他服务<br />(技术服务费)')
                                        "></div>
                                </div>
                                <div
                                    class="col-9 cont-right"
                                    style="background-color: rgb(242, 238, 234)">
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('免') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('免') }}
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="text-val-tit flexbox">
                                            {{ $t('免') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-box items-stretch">
                                <div class="rules">
                                    <div>
                                        1、{{
                                            $t(
                                                '正常包网和代运营包网二者可互相转换升级(初次转换方案后3-4个月内不可再转换,转换前需由我方审核核实是否可转换)'
                                            )
                                        }}
                                    </div>
                                    <div>
                                        2、{{
                                            $t(`我们将为您设置为期三个月的扶持期,网站正式上线前一个月不做业绩要求,但扶持期后三个月仍无法达到月6亿vnd盈利,将收取相应服务费或闭站处理;`)
                                        }}
                                    </div>
                                    <div>
                                        3、{{
                                            $t(
                                                '站主在启动运营后需自己承担输赢,需预存好自己运营的基础费用,GTG作为服务商不承担此费用。'
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="display flexbox col">
                    <div class="title">{{ $t('匠心制作') }}</div>
                    <div class="desc">{{ $t('包网塑造精品平台') }}</div>
                    <div class="btns flexbox">
                        <div
                            :class="['title-text-tip flexbox', isWeb && 'on']"
                            @click="setActive(1)"
                            :style="`background-image: url('${
                                isWeb ? btnon : btnoff
                            }')`">
                            {{ $t('电脑端WEB') }}
                        </div>
                        <div
                            :class="['title-text-tip flexbox', !isWeb && 'on']"
                            @click="setActive"
                            :style="`background-image: url('${
                                !isWeb ? btnon : btnoff
                            }')`">
                            {{ $t('手机端APP') }}
                        </div>
                    </div>
                    <div class="swipera" v-show="isWeb">
                        <div class="swiper swiper-container swiper-container2">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide swiper-item" v-for="(item, index) in swipera" :key="index">
                                    <img class="display-img" :src="b.img" v-for="(b, bi) in item" :key="bi" />
                                </div>
                            </div>
                            <div class="swiper-paginationa swiper-pagination"></div>
                        </div>
                    </div>
                    <div class="swiperb" v-if="!isWeb">
                        <div class="swiper swiper-container 4">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide swiper-item" v-for="(item, index) in swiperb" :key="index">
                                    <img class="display-img" :src="b.img" v-for="(b, bi) in item" :key="bi" />
                                </div>
                            </div>
                            <div class="swiper-paginationa swiper-pagination"></div>
                        </div>
                    </div>
                </div>
                <div class="cooperate flexbox col">
                    <div class="title">{{ $t('商务合作') }}</div>
                    <div class="desc">{{ $t('轻松五步，快速开站') }}</div>
                    <div class="merchant-content">
                        <div
                            class="merchant-after flexbox"
                            v-for="(item, index) in cops"
                            :key="index">
                            <img :src="item.img" class="stepimg" width="100" />
                            <div class="infos flexbox">
                                <span class="step">0{{ index + 1 }}</span>
                                <span>{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contact">
                    <div
                        @click="toContact"
                        class="title-text-tip flexbox on"
                        :style="`background-image: url('${btnon}')`">
                        {{ $t('联系商务') }}
                    </div>
                </div>
            </div>
            <div class="footer flexbox">
                Copyright© 2023 GoTech Group
            </div>
        </div>
        <div :class="['rightmenu', menuout && 'in', menuout === false && 'out' ]">
            <div class="text-white flexbox se">
                <div class="img-div cursor-pointer">
                    <a :href="curContact.Telephone" target='_blank'>
                        <img src="../assets/images/phone.svg" />
                        <div class="text">Telephone</div>
                    </a>
                </div>
                <div class="img-div">
                    <a :href="curContact.Whatsapp" target='_blank'>
                        <img src="../assets/images/whatsapp.svg" />
                        <div class="text">Whatsapp</div>
                    </a>
                </div>
                <div class="img-div">
                    <a :href="curContact.Telegram" target='_blank'>
                        <img src="../assets/images/Telegram.svg" />
                        <div class="text">Telegram</div>
                    </a>
                </div>
                <div class="img-div">
                    <a :href="curContact.Skype" target='_blank'>
                        <img src="../assets/images/skype.svg" />
                        <div class="text">Skype</div>
                    </a>
                </div>
                <!-- <div class="top" @click="toTop">
                    <img class="to_top" src="../assets/images/top.svg" />
                </div> -->
            </div>
        </div>
        <div :class="['bottonforout flexbox', menuout && 'rotate']" @click="menuout = !menuout">
            <img class="btn" src="../assets/images/bottir.png" width="37" alt="">
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    import 'swiper/css/swiper.min.css'
    export default {
        name: 'mobile-page',
        data() {
            return {
                banner: {
                    'zh_CN': [
                        require('../assets/images/hbanner1.png'),
                        require('../assets/images/hbanner2.png'),
                        require('../assets/images/hbanner3.png')
                    ],
                    'vi': [
                        require('../assets/images/hbanner1vi.png'),
                        require('../assets/images/hbanner2vi.png'),
                        require('../assets/images/hbanner3vi.png')
                    ]
                },
                menuout: true,
                btnon: require('../assets/images/game_btn_active.png'),
                btnoff: require('../assets/images/game_btn_duf.png'),
                isWeb: true,
                swipera: [
                    [
                        { img: require('../assets/images/WEB/1.png') },
                        { img: require('../assets/images/WEB/2.png') },
                        { img: require('../assets/images/WEB/3.png') },
                        { img: require('../assets/images/WEB/4.png') }
                    ],
                    [
                        { img: require('../assets/images/WEB/5.png') },
                        { img: require('../assets/images/WEB/6.png') },
                        { img: require('../assets/images/WEB/7.png') },
                        { img: require('../assets/images/WEB/8.png') }
                    ],
                    [
                        { img: require('../assets/images/WEB/9.png') }
                    ]
                ],
                swiperb: [
                [
                        { img: require('../assets/images/H5/1.png') },
                        { img: require('../assets/images/H5/2.png') },
                        { img: require('../assets/images/H5/3.png') },
                        { img: require('../assets/images/H5/4.png') }
                    ],
                    [
                        { img: require('../assets/images/H5/5.png') },
                        { img: require('../assets/images/H5/6.png') },
                        { img: require('../assets/images/H5/7.png') },
                        { img: require('../assets/images/H5/8.png') }
                    ],
                    [
                        { img: require('../assets/images/H5/9.png') }
                    ]
                ],
                curLang: {},
                contact: {
                    'gtg11': {
                        Whatsapp: 'https://wa.me/639298133164',
                        Telegram: 'https://t.me/gtg8vn',
                        Skype: 'skype:live:.cid.51ccc3f9709b29c5?chat',
                        fb: 'https://www.facebook.com/profile.php?id=61552222931819',
                        Telephone: 'https://t.me/+639298133164'
                    },
                    'gtg22': {
                        Whatsapp: 'https://wa.me/639619621744',
                        Telegram: 'https://t.me/GTGVN88',
                        Skype: 'skype:live:.cid.a7fd9937076b5888?chat',
                        fb: ' https://www.facebook.com/profile.php?id=61553645334223',
                        Telephone: 'https://t.me/+639619621744'
                    }
                },
                curContact: {}
            };
        },
        created () {
            const langua = localStorage.getItem('lang') || 'zh_CN'
            this.curLang = this.lang.find(el => el.lang === langua);
            if (location.origin.includes('gtg22')) {
                this.curContact = this.contact['gtg22']
            } else this.curContact = this.contact['gtg11']
        },
        mounted() {
            this.delHandleMouseEnter();
            this.$nextTick(() => {
                new Swiper('.swiper-container1', {
                    loop: true,
                    pagination: {
                        el: '.swiper-paginationa',
                    },
                });
                new Swiper('.swiper-container2', {
                    loop: true,
                    pagination: {
                        el: '.swiper-paginationa',
                    },
                });
                new Swiper('.swiper-container4', {
                    loop: true,
                    pagination: {
                        el: '.swiper-paginationa',
                    },
                });
            });
        },
        computed: {
            lang () {
                return [
                    {
                        img: require('../assets/images/china.png'),
                        name: this.$t('简体中文'),
                        lang: 'zh_CN'
                    },
                    {
                        img: require('../assets/images/yuenan.png'),
                        name: this.$t('越南语'),
                        lang: 'vi'
                    }
                ]
            },
            service () {
                return [
                    [{
                        img: require('../assets/images/baokefu.png'),
                        name: this.$t('包客服')
                    },
                    {
                        img: require('../assets/images/baofengkong.png'),
                        name: this.$t('包风控')
                    }],
                    [{
                        img: require('../assets/images/baoyunying.png'),
                        name: this.$t('包运营')
                    },
                    {
                        img: require('../assets/images/baochurukuan.png'),
                        name: this.$t('包出入款人员')
                    }],
                    [{
                        img: require('../assets/images/baojinliu.png'),
                        name: this.$t('包金流')
                    }, {}]
                ]
            },
            cops () {
                return [
                    {
                        img: require('../assets/images/yxxqqt.png'),
                        name: this.$t('意向需求洽谈')
                    },
                    {
                        img: require('../assets/images/qdhzxy.png'),
                        name: this.$t('签订合作协议')
                    },
                    {
                        img: require('../assets/images/jzxjgt.png'),
                        name: this.$t('建站细节沟通')
                    },
                    {
                        img: require('../assets/images/ptzdbs.png'),
                        name: this.$t('平台站点部署')
                    },
                    {
                        img: require('../assets/images/zssxyy.png'),
                        name: this.$t('正式上线运营')
                    }
                ]
            }
        },
        methods: {
            toContact () {
                window.open(this.curContact.fb, '_blank')
            },
            setLang (lang) {
                this.$i18n.locale = lang.lang
                localStorage.setItem('lang',this.$i18n.locale)
                // console.log(this.$i18n);
                this.curLang = lang
                document.body.classList = [lang.lang]
            },
            setActive(num) {
                this.isWeb = num === 1;
                this.$nextTick(() => {
                    new Swiper('.swiper-container3', {
                        loop: true,
                        pagination: {
                            el: '.swiper-paginationa',
                        },
                    });
                })
            },
            delHandleMouseEnter() {
                this.$refs.swipera &&
                    (this.$refs.swipera.handleMouseEnter = () => {});
                this.$refs.swiperb &&
                    (this.$refs.swiperb.handleMouseEnter = () => {});
            },
            toTop() {
                let sTop =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (sTop > 0) {
                    window.requestAnimationFrame(this.toTop);
                    window.scrollTo(0, sTop - sTop / 8);
                }
            }
        }
    };
</script>

<style lang="less" scoped>
    @import url('../styles/mobile.less');
    ::v-deep .swiper-pagination-bullet {
        border-radius: 10px;
        padding: 0;
        height: 14px;
        width: 14px;
        margin: 0 10px;
        position: relative;
        &.swiper-pagination-bullet-active{
            height: 16px;
            width: 16px;
            background: linear-gradient(#ffbe8e, #ff8546);
            &::after{
                display: block;
                content: '';
                height: 14px;
                width: 14px;
                background: #fff;
                border-radius: 10px;
                position: absolute;
                top: 1px;
                left: 1px;
            }
            &::before {
                display: block;
                content: '';
                position: absolute;
                border-radius: 10px;
                height: 12px;
                width: 12px;
                background: linear-gradient(#ffbe8e, #ff8546);
                transform: translate(2px, 2px);
                z-index: 10;
            }
        }
        
    }
</style>
