(function (doc, window) {
  var docEl = doc.documentElement,
    sizeEl = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      var gamePreView = 5; //最近参与游戏slides
      if ((clientWidth >= 1400) && clientWidth * 1 !== 2560) {
        docEl.style.fontSize = "100px";
      } else if ((clientWidth >= 1200 && clientWidth < 1400) || clientWidth * 1 === 2560) {
        docEl.style.fontSize = "90px";
      } else if (clientWidth >= 750 && clientWidth < 1200) {
        docEl.style.fontSize = "85px";
      } else {
        docEl.style.fontSize = 100 * (clientWidth / 375) + "px";
      }
      window._gamePreView = gamePreView
    };
  if (!doc.addEventListener) return;
  window.addEventListener(sizeEl, recalc, false);
  window.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);
